import { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import useAuth from '../../auth/useAuth';
import { useSoundStatus } from '../../context/SoundStatusContext';

import Layout from '../UI/introGameLayout/layout';

import BackgroundImage from './images/game-background-blurred.jpg';

import {
  gamesQuery,
  getGamesSessions,
  createGameSession, 
  updateGameSession,
  scenarioLevelsQuery,
  getDecisions,
  makeDecision,
  updateDecision
} from './api';

import Intro from '../UI/game/intro';
import Modal from '../UI/modal';
import BalanceSheet from '../UI/balancedEquation/balanceSheet';

import TransactionRecords from '../UI/balancedEquation/transactionRecords';

import './styles.css';

import GameProcessSound from './sounds/game-process.mp3';
import ClickSound from './sounds/click.mp3';
import UpdateBalanceSheetSound from './sounds/update-balance-sheet.mp3';
import AnswerCorrectSound from './sounds/answer-correct.mp3';
import AnswerWrongSound from './sounds/answer-wrong.mp3';
import ItemDroppedSound from './sounds/item-dropped.mp3';

import SignMinus from './images/icon-minus.svg';
import SignPlus from './images/icon-plus.svg';

const getHintContentDecisionTypes = (levelNumber) => {
  return levelNumber > 3 ? 3 : levelNumber;
}

export default function BalancedEquationTutorial() {
  const gameProcessEffect = useRef(new Audio(GameProcessSound));
  
  const clickEffect = useRef();
  const itemDroppedEffect = useRef();
  const updateBalanceSheetEffect = useRef();
  const answerCorrectEffect = useRef();
  const answerWrongEffect = useRef();
  useEffect(() => {
    const clickAudio = new Audio(ClickSound);  
    clickAudio.preload = 'auto';  
    clickEffect.current = clickAudio;

    const itemDroppedAudio = new Audio(ItemDroppedSound);  
    itemDroppedAudio.preload = 'auto';  
    itemDroppedEffect.current = itemDroppedAudio;
    itemDroppedEffect.current.volume = 0.18;

    const updateBalanceSheetAudio = new Audio(UpdateBalanceSheetSound);  
    updateBalanceSheetAudio.preload = 'auto';  
    updateBalanceSheetEffect.current = updateBalanceSheetAudio;
    updateBalanceSheetEffect.current.volume = 0.3;
    
    const answerCorrectAudio = new Audio(AnswerCorrectSound);  
    answerCorrectAudio.preload = 'auto';  
    answerCorrectEffect.current = answerCorrectAudio;
    
    const answerWrongAudio = new Audio(AnswerWrongSound);  
    answerWrongAudio.preload = 'auto';  
    answerWrongEffect.current = answerWrongAudio;
  
    return () => {
      clickAudio.pause();
      itemDroppedAudio.pause();
      updateBalanceSheetAudio.pause();
      answerCorrectAudio.pause();
      answerWrongAudio.pause();
    };
  }, []);

  const navigate = useNavigate();

  const params = useParams();
  const levelNumber = Number(params.levelNumber);

  const { getUser, getCurrentBalancedGameId } = useAuth();

  const user = getUser();
  const gameId = getCurrentBalancedGameId();

  const { soundStatus } = useSoundStatus();

  const [scenarioId, setScenarioId] = useState(0);

  const { data: games } = useQuery(gamesQuery()) || {};

  useEffect(() => {
    if(games) {
      const game = games.find(game => game.game_type.includes('Balanced Equation') && game.id === gameId);

      if(game && game.scenario) {
        setScenarioId(game.scenario);
      }
    }
  }, [games, gameId]);

  const [questions, setQuestions] = useState([]);
  const [activeQuestionId, setActiveQuestionId] = useState(0);
  const [questionAnsweredCorrectly, setQuestionAnsweredCorrectly] = useState(0);

  const [topOptions, setTopOptions] = useState([]);
  const [changeTypeOptions, setChangeTypeOptions] = useState([]);
  const [coreTypeOptions, setCoreTypeOptions] = useState([]);
  const [transactionTypeOptions, setTransactionTypeOptions] = useState([]);

  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const playClickSound = () => {
      if (soundStatus === 'enabled') {
        clickEffect.current.play().catch(e => console.error('Error playing click sound:', e));
      }
    };

    document.addEventListener('click', playClickSound);

    return () => {
      document.removeEventListener('click', playClickSound);
    };
  }, [soundStatus]);

  /* Prevent sound from playing on other pages */
  useEffect(() => {
    const currentGameProcessEffect = gameProcessEffect.current;
  
    return () => {
      if (currentGameProcessEffect) {
        currentGameProcessEffect.pause();
      }
    };
  }, [location]);

  /* play background music */
  useEffect(() => {  
    if (isPlaying && soundStatus === 'enabled') {
      gameProcessEffect.current.volume = 0.09;
      gameProcessEffect.current.loop = true;
      gameProcessEffect.current.play().catch(e => console.log('Error playing game process sound:', e));
    } else {
      gameProcessEffect.current.pause();
    }
  
    return () => {
      gameProcessEffect.current.pause();
    };
  }, [isPlaying, soundStatus]);

  const [totalScore, setTotalScore] = useState(0);

  const [isIntroVisible, setIsIntroVisible] = useState(true);

  const handleIntroClose = () => {
    setIsIntroVisible(false);
    setIsPlaying(true);
  }

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [scenarioLevelDescription, setScenarioLevelDescription] = useState('');

  const [assets, setAssets] = useState(0);
  const [liabilities, setLiabilities] = useState(0);
  const [equity, setEquity] = useState(0);

  const [assetsChildren, setAssetsChildren] = useState([]);
  const [liabilitiesChildren, setLiabilitiesChildren] = useState([]);
  const [equityChildren, setEquityChildren] = useState([]);

  const resetBalanceSheet = () => {
    setAssets(0);
    setLiabilities(0);
    setEquity(0);
    

    assetsChildrenRef.current = [];
    liabilitiesChildrenRef.current = [];
    equityChildrenRef.current = [];
    setAssetsChildren([]);
    setLiabilitiesChildren([]);
    setEquityChildren([]);
  }

  const assetsChildrenRef = useRef(assetsChildren);
  const liabilitiesChildrenRef = useRef(liabilitiesChildren);
  const equityChildrenRef = useRef(equityChildren);

  const [time, setTime] = useState(0);
  const timeRef = useRef(time);
  useEffect(() => {
    timeRef.current = time;
  }, [time]);

  useEffect(() => {
    if (!isPlaying) return;
    const timerInterval = setInterval(() => {
      const newTime = timeRef.current + 1;
      setTime(newTime);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [isPlaying]);

  const [answerStatus, setAnswerStatus] = useState(null);
  const [isExplanationVisible, setIsExplanationVisible] = useState(false);
  const [explanationContent, setExplanationContent] = useState(null);

  const [isSubmitButtonShown, setIsSubmitButtonShown] = useState(true);
  
  const { data: gamesSessions } = useQuery({
    queryKey: ['gamesSessionsTutorial'],
    queryFn: getGamesSessions,
    refetchOnWindowFocus: false,
    cacheTime: 0
  });

  const [currentGameSessionId, setCurrentGameSessionId] = useState(0);

  useEffect(() => {
    if(gamesSessions && gameId && user) {
      const currentGameSession = gamesSessions.find(session => session.user === user?.id && session.game === gameId && !session.completed);
      setCurrentGameSessionId(currentGameSession?.id || 0);
    }
  }, [gamesSessions, gameId, user]);

  const { data: decisionsResponse, isSuccess: isDecisionsSuccess } = useQuery({
    queryKey: [`decisionsTutorialResponse_${levelNumber}`, { userId: user?.id, gameId, level: levelNumber }],
    queryFn: () => getDecisions({ userId: user?.id, gameId, level: levelNumber }),
    enabled: !!user?.id && !!gameId,
    refetchOnWindowFocus: false
  });

  const queryClient = useQueryClient();

  const createGameSessionMutation = useMutation({
    mutationFn: (data) => createGameSession(data),
    onSuccess: () => {
      queryClient.invalidateQueries(['gamesSessionsTutorial']);
    },
    onError() {
      console.log('Server error');
    }
  });
  
  const updateGameSessionMutation = useMutation({
    mutationFn: (data) => updateGameSession(data),
    onSuccess: () => {
      queryClient.invalidateQueries(['gamesSessionsTutorial']);
    },
    onError() {
      console.log('Server error');
    }
  });

  useEffect(() => {
    if(gameId && gamesSessions && levelNumber === 1) {
      const currentGameSessions = gamesSessions.filter(session => session.user === user?.id && session.game === gameId);
      
      const incompleteSession = currentGameSessions.find(session => !session.completed);
      if (incompleteSession?.is_ready) {
        navigateToGame();
      }
    }
  }, [gamesSessions, navigate, user?.id, gameId, levelNumber]);

  const scenarioLevelsResponse = useQuery(scenarioLevelsQuery({scenarioId: scenarioId, level: levelNumber})) || {};
  const scenarioLevels = scenarioLevelsResponse.data || null;

  useEffect(() => {
    console.log('scenarioLevels?.length', scenarioLevels?.length);
    console.log('isDecisionsSuccess', isDecisionsSuccess);
    if (scenarioLevels?.length && isDecisionsSuccess) {
      resetBalanceSheet();
      const scenarioLevel = scenarioLevels.find(scenarioLevel => scenarioLevel.level === levelNumber);
      const decisionsMade = decisionsResponse || [];
      console.log('decisionsMade', decisionsMade);

      setScenarioLevelDescription(scenarioLevel.description);

      const {
        topOptions,
        changeTypeOptions,
        coreTypeOptions,
        transactionTypeOptions
      } = scenarioLevel.be_options.reduce((acc, item) => {
        let { name, description } = item.account_item;
        if (!description) {
          description = name;
        }

        const formattedItem = {
          id: item.id,
          order: item.order,
          accountItemId: item.account_item.id,
          name,
          description,
          type: ''
        };

        if (['Assets', 'Liabilities', 'Equity'].includes(item.account_item.name)) {
          acc.topOptions.push(formattedItem);
        } else if (['Increase', 'Decrease'].includes(item.account_item.name)) {
          formattedItem.name = name === 'Increase' ? <img src={SignPlus} alt="Plus" /> : <img src={SignMinus} alt="Minus" />;
          formattedItem.type = 'change';
          acc.changeTypeOptions.push(formattedItem);
        } else if (['Debit', 'Credit'].includes(item.account_item.name)) {
          formattedItem.type = 'transaction';
          acc.transactionTypeOptions.push(formattedItem);
        } else {
          formattedItem.type = 'core';
          acc.coreTypeOptions.push(formattedItem);
        }

        return acc;
      }, { topOptions: [], changeTypeOptions: [], coreTypeOptions: [], transactionTypeOptions: [] });

      topOptions.sort((a, b) => a.order - b.order);
      changeTypeOptions.sort((a, b) => a.order - b.order);
      coreTypeOptions.sort((a, b) => a.order - b.order);
      transactionTypeOptions.sort((a, b) => a.order - b.order);

      setTopOptions(topOptions);
      setChangeTypeOptions(changeTypeOptions);
      setCoreTypeOptions(coreTypeOptions);
      setTransactionTypeOptions(transactionTypeOptions);

      const countOptionsToBeFilled = !!changeTypeOptions.length + !!coreTypeOptions.length + !!transactionTypeOptions.length;
      setOptionsToBeFilled(countOptionsToBeFilled + 1); // +1 since we always need to have non draggable value inside drop area

      const unansweredQuestions = [];
      let allCorrectDecisionsScore = 0;
      
      scenarioLevel.be_questions
        .sort((a, b) => a.order - b.order)
        .forEach(question => {
          const relatedCorrectDecisions = decisionsMade.filter(decision => decision.question === question.id && decision.is_correct === true && decision.game_session === currentGameSessionId);
          const hasCorrectlyAnsweredDecision = relatedCorrectDecisions.some(decision => decision.question_answered_correctly === true);

          const questionScore = relatedCorrectDecisions.reduce((sum, decision) => sum + decision.score_points, 0);
          allCorrectDecisionsScore += questionScore;
          
          console.log('hasCorrectlyAnsweredDecision', hasCorrectlyAnsweredDecision);
          if (!hasCorrectlyAnsweredDecision) {
            unansweredQuestions.push({
              id: question.id,
              value: question.value,
              description: question.description
            });
          } else {
            // navigateToGame();
          }
        });

      setTotalScore(allCorrectDecisionsScore);

      if(unansweredQuestions.length) {
        setQuestions(unansweredQuestions);
        setActiveQuestionId(unansweredQuestions[0].id);
      }
    }
  }, [scenarioLevels, decisionsResponse, isDecisionsSuccess, levelNumber]);

  const shouldUpdateBalanceSheetSoundPlayRef = useRef(true);

  const balanceSheetTotalChange = (optionName, value) => {
    if (soundStatus === 'enabled' && shouldUpdateBalanceSheetSoundPlayRef.current !== false) {
      shouldUpdateBalanceSheetSoundPlayRef.current = false;
      setTimeout(() => {
        updateBalanceSheetEffect.current.play().catch(e => console.log('Error playing item hit spike sound:', e));
        shouldUpdateBalanceSheetSoundPlayRef.current = true;
      }, 300);
    }

    if (optionName === "Assets") {
      setAssets(prevAssets => prevAssets + value);
    } else if (optionName === "Liabilities") {
      setLiabilities(prevLiabilities => prevLiabilities + value);
    } else if (optionName === "Equity") {
      setEquity(prevEquity => prevEquity + value);
    }
  }
  
  const balanceSheetChildrenChange = (optionName, item, action, canRemove = false) => {
    if (optionName === "Assets") {
      if (action === 'add') {
        const existingItemIndex = assetsChildrenRef.current.findIndex(child => child.name === item.name);
  
        if (existingItemIndex !== -1) {
          const updatedChildren = [...assetsChildrenRef.current];
          updatedChildren[existingItemIndex].value += item.value;
          if (updatedChildren[existingItemIndex].value === 0 && canRemove) {
            updatedChildren.splice(existingItemIndex, 1); // Remove the item if value is 0
          }
          setAssetsChildren(updatedChildren);
          assetsChildrenRef.current = updatedChildren;  // Update the ref
        } else {
          const newChildren = [...assetsChildrenRef.current, item];
          setAssetsChildren(newChildren);
          assetsChildrenRef.current = newChildren;  // Update the ref
        }
      } else if (action === 'remove') {
        const newChildren = assetsChildrenRef.current.filter(child => child.name !== item.name);
        setAssetsChildren(newChildren);
        assetsChildrenRef.current = newChildren;  // Update the ref
      }
    } else if (optionName === "Liabilities") {
      if (action === 'add') {
        const existingItemIndex = liabilitiesChildrenRef.current.findIndex(child => child.name === item.name);
  
        if (existingItemIndex !== -1) {
          const updatedChildren = [...liabilitiesChildrenRef.current];
          updatedChildren[existingItemIndex].value += item.value;
          if (updatedChildren[existingItemIndex].value === 0 && canRemove) {
            updatedChildren.splice(existingItemIndex, 1); // Remove the item if value is 0
          }
          setLiabilitiesChildren(updatedChildren);
          liabilitiesChildrenRef.current = updatedChildren;  // Update the ref
        } else {
          const newChildren = [...liabilitiesChildrenRef.current, item];
          setLiabilitiesChildren(newChildren);
          liabilitiesChildrenRef.current = newChildren;  // Update the ref
        }
      } else if (action === 'remove') {
        const newChildren = liabilitiesChildrenRef.current.filter(child => child.name !== item.name);
        setLiabilitiesChildren(newChildren);
        liabilitiesChildrenRef.current = newChildren;  // Update the ref
      }
    } else if (optionName === "Equity") {
      if (action === 'add') {
        const existingItemIndex = equityChildrenRef.current.findIndex(child => child.name === item.name);
  
        if (existingItemIndex !== -1) {
          const updatedChildren = [...equityChildrenRef.current];
          updatedChildren[existingItemIndex].value += item.value;
          if (updatedChildren[existingItemIndex].value === 0 && canRemove) {
            updatedChildren.splice(existingItemIndex, 1); // Remove the item if value is 0
          }
          setEquityChildren(updatedChildren);
          equityChildrenRef.current = updatedChildren;  // Update the ref
        } else {
          const newChildren = [...equityChildrenRef.current, item];
          setEquityChildren(newChildren);
          equityChildrenRef.current = newChildren;  // Update the ref
        }
      } else if (action === 'remove') {
        const newChildren = equityChildrenRef.current.filter(child => child.name !== item.name);
        setEquityChildren(newChildren);
        equityChildrenRef.current = newChildren;  // Update the ref
      }
    }
  };

  const [filledRecords, setFilledRecords] = useState([]);

  const [optionsToBeFilled, setOptionsToBeFilled] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  
  const [isTopHintShown, setIsTopHintShown] = useState(true);
  const [isChangeHintShown, setIsChangeHintShown] = useState(false);
  const [changeHintContent, setChangeHintContent] = useState(levelNumber === 1 ? <span>Assuming the above transaction increase Assets, let’s drag and drop the “+” sign to assets</span> : <span><b>Decision 1:</b> decide if the transaction increase and/or decrease the Balance Sheet Elements.</span>);
  const [isCoreHintShown, setIsCoreHintShown] = useState(false);
  const [isTransactionHintShown, setIsTransactionHintShown] = useState(false);
  const [isSubmitHintShown, setIsSubmitHintShown] = useState(false);
  const [wasSubmitHintShow, setWasSubmitHintShow] = useState(false);

  useEffect(() => {
    const matchingRecords = filledRecords.filter(record => record.droppedItemsCount === optionsToBeFilled);
    const recordsWithDroppedItems = filledRecords.filter(record => record.droppedItemsCount > 0);

    if (
      matchingRecords.length !== 2 
        || recordsWithDroppedItems.length !== 2
        || (
          matchingRecords[0].optionAccountItemId === matchingRecords[1].optionAccountItemId
            && (
              matchingRecords[0].changeId === matchingRecords[1].changeId
                || (matchingRecords[0].coreId && matchingRecords[1].coreId && (matchingRecords[0].coreId === matchingRecords[1].coreId))
                || (matchingRecords[0].transactionId && matchingRecords[1].transactionId && (matchingRecords[0].transactionId === matchingRecords[1].transactionId))
            )
      )
    ) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
      if(!wasSubmitHintShow) {
        const submitHintContent = levelNumber === 1 
          ? <span>Great! You can now submit because you have two records with a decision in each.</span>
          : <span>Great! You can now submit because you have two records with {getHintContentDecisionTypes(levelNumber)} types of decisions in each.</span>;
        
        setSubmitButtonHintContent(submitHintContent);
        setIsSubmitHintShown(true);

        setWasSubmitHintShow(true);
      }
    }
  }, [filledRecords, optionsToBeFilled, levelNumber, wasSubmitHintShow]);

  const onDropAreaChange = ({
    id,
    droppedItemsCount,
    box,
    optionAccountItemId,
    optionName,
    changeId,
    changeAccountItemId,
    changeDescription,
    coreId,
    coreName,
    transactionId,
    transactionAccountItemId,
    transactionName
  }) => {
    if (soundStatus === 'enabled') {
      itemDroppedEffect.current.play().catch(e => console.log('Error playing item dropped sound:', e));
    }
    
    if(levelNumber === 1 && !firstDropWasMade) {
      setIsChangeHintShown(true);
      setChangeHintContent(<span>Excellent! To make this equation balance, we’re going to assume that the transaction also increases Equity. Let’s drag and drop the “+” sign to Equity</span>);
      setFirstDropWasMade(true);
    }
    if(shouldBalanceSheetSlideIn) {
      setShouldBalanceSheetSlideIn(false);
    }
    setFilledRecords(prevRecords => {
      const recordExists = prevRecords.some(record => record.id === id);
      let updatedRecords;
  
      if(recordExists) {
        updatedRecords = prevRecords.map(record => {
          if (record.id === id) {
            const updatedRecord = { ...record, droppedItemsCount };
            if(changeId) {
              updatedRecord.changeId = changeId;
              updatedRecord.changeAccountItemId = changeAccountItemId;
              updatedRecord.changeDescription = changeDescription;
            } else if(changeId === 0) {
              updatedRecord.changeId = null;
              updatedRecord.changeAccountItemId = null;
              updatedRecord.changeDescription = null;
            }
            if(coreId) {
              updatedRecord.coreId = coreId;
              updatedRecord.coreName = coreName;
            } else if(coreId === 0) {
              updatedRecord.coreId = null;
              updatedRecord.coreName = null;
            }
            if(transactionId){
              updatedRecord.transactionId = transactionId;
              updatedRecord.transactionAccountItemId = transactionAccountItemId;
              updatedRecord.transactionName = transactionName;
            } else if(transactionId === 0) {
              updatedRecord.transactionId = null;
              updatedRecord.transactionAccountItemId = null;
              updatedRecord.transactionName = null;
            }
            return updatedRecord;
          }
          return record;
        });
      } else {
        const recordItem = {
          id,
          droppedItemsCount,
          box,
          optionAccountItemId,
          optionName,
          changeId,
          changeAccountItemId,
          changeDescription,
          coreId,
          coreName,
          transactionId,
          transactionAccountItemId,
          transactionName
        };
        updatedRecords = [...prevRecords, recordItem];
      }
  
      return updatedRecords;
    });
  };

  const decisionMutation = useMutation({
    mutationFn: (data) => makeDecision(data)
  });
  
  const updateDecisionMutation = useMutation({
    mutationFn: (data) => updateDecision(data)
  });

  const [resetKey, setResetKey] = useState(0);
  const [incorrectDecisions, setIncorrectDecisions] = useState([]);

  const handleNextButtonClick = () => {
    setResetKey(prevKey => prevKey + 1);
    setFilledRecords([]);
    setIsSubmitButtonShown(true);
    setIsExplanationVisible(false);
    setIncorrectDecisions([]);
    setScenarioLevelDescription('');

    if(questionAnsweredCorrectly) {
      setIsPlaying(false);
      setIsModalVisible(true);
    }

    setIsItemsDisabled(false);
  }

  const handleSubmitButtonClick = async () => {
    setIsLoading(true);
    shouldUpdateBalanceSheetSoundPlayRef.current = false;

    const latestFilledRecords = filledRecords.filter(filledRecord => filledRecord.droppedItemsCount);
    const activeQuestion = questions.find(question => question.id === activeQuestionId);
  
    const timeElapsed = timeRef.current;
  
    const decisionResults = [];
    const timestamp = new Date().toISOString();
    const groupId = timestamp.replace(/\D/g, '');

    let gameSessionId = currentGameSessionId;

    if (!gameSessionId) {
      try {
        const newSession = await createGameSessionMutation.mutateAsync({
          game: gameId,
          user: user?.id,
        });
  
        gameSessionId = newSession.id;
        setCurrentGameSessionId(gameSessionId);
      } catch (error) {
        console.error('Error during game session creation:', error);
      }
    }

    if(gameSessionId) {
      try {
        for (const record of latestFilledRecords) {
          if (record.changeId) {
            const result = await decisionMutation.mutateAsync({
              user: user?.id,
              game: gameId,
              timeElapsed,
              question: activeQuestion.id,
              box: record.box,
              itemOption: record.optionAccountItemId,
              selectedOption: record.changeId,
              timestamp,
              groupId,
              gameSessionId
            });
            decisionResults.push({
              id: record.changeId,
              decisionId: result.id,
              isCorrect: result.is_correct,
              type: 'change',
              description: record.changeDescription,
              optionAccountItemId: record.optionAccountItemId,
              optionName: record.optionName,
              isSecondDropArea: record.box === 'Bottom',
              questionId: activeQuestion.id,
              score: result.score_points,
              name: record.changeDescription,
              value: result.value
            });
          }
    
          if (record.coreId) {
            const result = await decisionMutation.mutateAsync({
              user: user?.id,
              game: gameId,
              timeElapsed,
              question: activeQuestion.id,
              box: record.box,
              itemOption: record.optionAccountItemId,
              selectedChange: record.changeAccountItemId,
              selectedOption: record.coreId,
              timestamp,
              groupId,
              gameSessionId
            });
            decisionResults.push({
              id: record.coreId,
              decisionId: result.id,
              isCorrect: result.is_correct,
              type: 'core',
              optionAccountItemId: record.optionAccountItemId,
              optionName: record.optionName,
              isSecondDropArea: record.box === 'Bottom',
              questionId: activeQuestion.id,
              score: result.score_points,
              name: record.coreName,
              value: result.value
            });
          }
    
          if (record.transactionId) {
            const result = await decisionMutation.mutateAsync({
              user: user?.id,
              game: gameId,
              timeElapsed,
              question: activeQuestion.id,
              box: record.box,
              itemOption: record.optionAccountItemId,
              selectedOption: record.transactionId,
              timestamp,
              groupId,
              gameSessionId
            });
            decisionResults.push({
              id: record.transactionId,
              decisionId: result.id,
              isCorrect: result.is_correct,
              type: 'transaction',
              optionAccountItemId: record.optionAccountItemId,
              optionName: record.optionName,
              isSecondDropArea: record.box === 'Bottom',
              questionId: activeQuestion.id,
              score: result.score_points,
              name: record.transactionName,
              value: result.value
            });
          }
        }
        
      } catch (error) {
        console.log('Some mutations failed:', error);
      } finally {
        /* balance sheet update sound can play after the correct or wrong decision sound is played */
        setTimeout(() => {
          shouldUpdateBalanceSheetSoundPlayRef.current = true;
        }, 500);
  
        const correctResults = decisionResults.filter(decisionResult => decisionResult.isCorrect);
  
        correctResults.filter(correctResult => correctResult.score).forEach(correctResult => {
          setTotalScore(prevTotalScore => prevTotalScore + correctResult.score);
        });
  
        const allResultsCorrect = correctResults.length === decisionResults.length;
  
        if (decisionResults.length && allResultsCorrect) {
          answerCorrectEffect.current.play().catch(e => console.log('Error playing answer correct sound:', e));
  
          updateDecisionMutation.mutate({id: decisionResults[0].decisionId});
          
          setQuestionAnsweredCorrectly(decisionResults[0].questionId);
          setAnswerStatus('correct');
  
          let firstUpdatedOptionName = '';
          switch(latestFilledRecords[0].optionName) {
            case 'Assets':
              firstUpdatedOptionName = 'an "Asset"';
              break;
            case 'Liabilities':
              firstUpdatedOptionName = 'a "Liability"';
              break;
            case 'Equity':
              firstUpdatedOptionName = 'an "Equity"';
              break;
            default:
              firstUpdatedOptionName = 'an "Asset"';
              break;
          }
          
          let secondUpdatedOptionName = '';
          switch(latestFilledRecords[1].optionName) {
            case 'Assets':
              secondUpdatedOptionName = 'an "Asset"';
              break;
            case 'Liabilities':
              secondUpdatedOptionName = 'a "Liability"';
              break;
            case 'Equity':
              secondUpdatedOptionName = 'an "Equity"';
              break;
            default:
              secondUpdatedOptionName = 'an "Asset"';
              break;
          }
  
          let explanation = '';
          switch(decisionResults.length) {
            case 2:
              explanation = `${firstUpdatedOptionName[0].toUpperCase() + firstUpdatedOptionName.slice(1)} should "${latestFilledRecords[0].changeDescription}" and ${secondUpdatedOptionName} should "${latestFilledRecords[1].changeDescription}". Click "Next" to continue.`;
              break;
            case 4:
              explanation = `${firstUpdatedOptionName[0].toUpperCase() + firstUpdatedOptionName.slice(1)} account, "${latestFilledRecords[0].coreName}", should "${latestFilledRecords[0].changeDescription}" and ${secondUpdatedOptionName} account, "${latestFilledRecords[1].coreName}", should "${latestFilledRecords[1].changeDescription}". Click "Next" to continue.`;
              break;
            case 6:
              explanation = `"${latestFilledRecords[0].coreName}" should be "${latestFilledRecords[0].transactionName}ed" since it’s ${firstUpdatedOptionName} that "${latestFilledRecords[0].changeDescription}d". "${latestFilledRecords[1].coreName}" should be "${latestFilledRecords[1].transactionName}ed" since it’s ${secondUpdatedOptionName} that "${latestFilledRecords[1].changeDescription}d". Click "Next" to continue.`;
              break;
            default:
              explanation = `${firstUpdatedOptionName[0].toUpperCase() + firstUpdatedOptionName.slice(1)} should "${latestFilledRecords[0].changeDescription}" and ${secondUpdatedOptionName} should "${latestFilledRecords[1].changeDescription}". Click "Next" to continue.`;
              break;
          }
          setExplanationContent(explanation);
          setIsExplanationVisible(true);
        } else {
          answerWrongEffect.current.play().catch(e => console.log('Error playing answer wrong sound:', e));
  
          const currentIncorrectDecisions = decisionResults.filter(decisionResult => !decisionResult.isCorrect);
          setIncorrectDecisions(currentIncorrectDecisions);
  
          let coreAdjustmentValue;
          decisionResults.forEach(decision => {
            if (decision.type === 'change') {
              const lowerCaseOptionName = decision.optionName.toLowerCase();
              const adjustmentValue = decision.description === 'increase' ? -activeQuestion.value : activeQuestion.value;
              coreAdjustmentValue = adjustmentValue;
        
              switch (lowerCaseOptionName) {
                case 'assets':
                  setAssets(prevAssets => prevAssets + adjustmentValue);
                  break;
                case 'liabilities':
                  setLiabilities(prevLiabilities => prevLiabilities + adjustmentValue);
                  break;
                case 'equity':
                  setEquity(prevEquity => prevEquity + adjustmentValue);
                  break;
                default:
                  console.error(`Unknown option name: ${lowerCaseOptionName}`);
              }
            } else if (decision.type === 'core') {
              balanceSheetChildrenChange(decision.optionName, {name: decision.name, value: coreAdjustmentValue}, 'add', true);
            }
          });
  
          setAnswerStatus('incorrect');
          setExplanationContent('Some or all of your decisions are not correct. Don’t worry, you’ll have another chance to answer this question. Click "Next" to continue.');
          setIsExplanationVisible(true);
        }
  
        setIsSubmitButtonShown(false);
        setIsLoading(false);
      }
    }

    setIsItemsDisabled(true);
  };

  const [isLoading, setIsLoading] = useState(false);

  const activeQuestion = questions.find(question => question.id === activeQuestionId);

  const [shouldBalanceSheetSlideIn, setShouldBalanceSheetSlideIn] = useState(levelNumber === 1 ? true : false);

  const handleReadyButtonClick = () => {
    if(levelNumber === 1) {
      if(gamesSessions.length) {
        if(currentGameSessionId) {
          updateGameSessionMutation.mutate({ id: currentGameSessionId, levelNumber, status: 'in_progress' });
        } else {
          createGameSessionMutation.mutate({ game: gameId, user: user?.id });
        }
      } else {
        createGameSessionMutation.mutate({ game: gameId, user: user?.id });
      }
    } else {
      updateGameSessionMutation.mutate({ id: currentGameSessionId, levelNumber, status: 'in_progress' });
      navigateToGame();
    }
  }

  const navigateToGame = () => {
    navigate(`/balanced-equation/level/${levelNumber}`);
  }


  // const [isAllHintsShown, setIsAllHintsShown] = useState(false);
  
  const topHintContent = levelNumber === 1 
    ? <span>In this level, you are deciding how each transaction increase and/or decrease Balance Sheet Elements (Assets, Liabilities, Equity).</span>
    : <span>In this level, you are making {getHintContentDecisionTypes(levelNumber)} types of decisions.</span>;

  const handleTopHintButtonClick = () => {
    setIsTopHintShown(false);
    setIsChangeHintShown(true);
  }

  const handleChangeHintButtonClick = () => {
    setIsChangeHintShown(false);
    
    if(levelNumber > 1) {
      setIsCoreHintShown(true);
    } else {
    }
  }

  const handleCoreHintButtonClick = () => {
    setIsCoreHintShown(false);

    if(levelNumber > 2) {
      setIsTransactionHintShown(true);
    } else {
    }
  }

  const handleTransactionHintButtonClick = () => {
    setIsTransactionHintShown(false);
  }

  const handleSubmitHintButtonClick = () => {
    setIsSubmitHintShown(false);
  }

  const [firstDropWasMade, setFirstDropWasMade] = useState(false);

  const [isItemsDisabled, setIsItemsDisabled] = useState(false);

  const [isSubmitHoverHintShown, setIsSubmitHoverHintShown] = useState(false);
  const [submitButtonHintContent, setSubmitButtonHintContent] = useState(<span></span>);

  const handleSubmitButtonMouseEnter = () => {
    if(isSubmitButtonShown && isButtonDisabled) {
      let submitMessage = '';

      const recordsWithDroppedItems = filledRecords.filter(record => record.droppedItemsCount > 0);

      if(recordsWithDroppedItems.length < 2) {
        submitMessage = 'To submit, there must be decisions in 2 records.';
      } else if(recordsWithDroppedItems.length === 2) {
        const bothHaveChangeId = recordsWithDroppedItems.every(record => record.changeId);
        if(!bothHaveChangeId) {
          submitMessage = 'Increase or decrease needs to be in 2 records.';
        } else {
          const bothHaveCoreId = recordsWithDroppedItems.every(record => record.coreId);
          if(!bothHaveCoreId && levelNumber >= 2) {
            submitMessage = 'An account needs to be in 2 records.';
          } else {
            const bothHaveTransactionId = recordsWithDroppedItems.every(record => record.transactionId);
            if(!bothHaveTransactionId  && levelNumber >= 3) {
              submitMessage = 'A debit or a credit needs to be in 2 records.';
            } else {
              if(recordsWithDroppedItems[0].changeId === recordsWithDroppedItems[1].changeId) {
                submitMessage = 'An increase or decrease decision is missing.';
              } else if(recordsWithDroppedItems[0].coreId === recordsWithDroppedItems[1].coreId) {
                submitMessage = 'An account decision is missing.';
              } else if(recordsWithDroppedItems[0].transactionId === recordsWithDroppedItems[1].transactionId) {
                submitMessage = 'A debit or credit decision is missing.';
              }
            }
          }
        }
      } else {
        submitMessage = 'There are too many records; decisions should be in no more than 2 records.';
      }

      setSubmitButtonHintContent(submitMessage);
      setIsSubmitHoverHintShown(true);
    }
  }

  const handleSubmitButtonMouseLeave = () => {
    setIsSubmitHoverHintShown(false);
  }

  return(
    <Layout
      backgroundImage={BackgroundImage}
      isHeaderVisible={true}
      isFooterVisible={true}
      totalScore={totalScore}
      scoreLabel="Points"
      isExplanationVisible={isExplanationVisible}
      explanationName="Bailey"
      isAvatarVisible={true}
      answerStatus={answerStatus}
      explanationContent={explanationContent}
    >
      {
        (isTopHintShown || isChangeHintShown || isCoreHintShown || isTransactionHintShown || isSubmitHintShown) &&
          <div className='background-overlay'/>
      }
      {
        isIntroVisible && (
          <Intro
            title={`Level ${levelNumber}`}
            description={scenarioLevelDescription}
            onShowingCompleted={handleIntroClose}
          />
      )}
      <TransactionRecords 
        transactionText={activeQuestion?.description}
        value={activeQuestion?.value}
        topOptions={topOptions}
        changeTypeOptions={changeTypeOptions}
        coreTypeOptions={levelNumber > 1 ? coreTypeOptions : []}
        transactionTypeOptions={levelNumber > 2 ? transactionTypeOptions : []}
        onChangeItemDrop={balanceSheetTotalChange}
        onCoreItemDrop={balanceSheetChildrenChange}
        onDropAreaChange={onDropAreaChange}
        isSubmitButtonShown={isSubmitButtonShown}
        onSubmitButtonClick={handleSubmitButtonClick}
        onSubmitButtonMouseEnter={handleSubmitButtonMouseEnter}
        onSubmitButtonMouseLeave={handleSubmitButtonMouseLeave}
        onNextButtonClick={handleNextButtonClick}
        isButtonLoading={isLoading}
        isButtonDisabled={isButtonDisabled}
        resetKey={resetKey}
        incorrectDecisions={incorrectDecisions}
        isTopHintShown={isTopHintShown}
        topHintContent={topHintContent}
        onTopHintButtonClick={handleTopHintButtonClick}
        isChangeHintShown={isChangeHintShown}
        isCoreHintShown={isCoreHintShown}
        isTransactionHintShown={isTransactionHintShown}
        changeHintContent={changeHintContent}
        onChangeHintButtonClick={handleChangeHintButtonClick}
        onCoreHintButtonClick={handleCoreHintButtonClick}
        onTransactionHintButtonClick={handleTransactionHintButtonClick}
        isSubmitHintShown={isSubmitHintShown || isSubmitHoverHintShown}
        submitHintContent={submitButtonHintContent}
        onSubmitHintButtonClick={!isSubmitHoverHintShown ? handleSubmitHintButtonClick : undefined}
        isItemsDisabled={isItemsDisabled}
        level={levelNumber}
      />
        <BalanceSheet
          assets={assets}
          liabilities={liabilities}
          equity={equity}
          assetsChildren={assetsChildren}
          liabilitiesChildren={liabilitiesChildren}
          equityChildren={equityChildren}
          shouldSlideIn={shouldBalanceSheetSlideIn}
        />
      {isModalVisible && (
        <Modal
          content={ 
            <>
              <div className="modal-content align-center">
                <p>Awesome!</p>
                <p>You’ve completed the tutorial!</p>
                <p>Ready to play? </p>
              </div>
              <div className="next-button" onClick={handleReadyButtonClick}>I’m Ready!</div>
            </>
          }
        />
      )}
    </Layout>
  );
}
