
import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import {
  getEquationScoresInsights
} from '../api';

import './styles.css';

import { navigation } from '../data';
import SubPagesLayout from '../../../UI/layout/subPages';
import { LeaderboardOverall } from '../../../UI/instructor/leaderboard/overall';

export default function InstructorInsightsDoubleEntry() {
  const navigate = useNavigate();

  const [filterBy, setFilterBy] = useState('Accuracy');
  const [data, setData] = useState(null);

  const filterByOptions = ['Accuracy', 'Points'];

  const { data: equationScoresInsights } = useQuery({
    queryKey: ['equationScoresInsights'],
    queryFn: async () => getEquationScoresInsights()
  });

  useEffect(() => {
    if(equationScoresInsights) {
      formatLeaderboardData();
    }
  }, [equationScoresInsights, filterBy]);

  const calculateAverage = (data, param) => {
    if (!data || data.length === 0) return 0;
    const total = data.reduce((sum, record) => sum + record[param], 0);

    return total / data.length;
  };

  const formatLeaderboardData = () => {
    let wholeLeaderboardData = [];

    const increaseDecreaseElementsScore = filterBy === 'Accuracy' ? calculateAverage(equationScoresInsights, 'increase_decrease_elements_accuracy') : calculateAverage(equationScoresInsights, 'increase_decrease_elements_points');
    const increaseDecreaseElementsFormattedScore = filterBy === 'Accuracy' ? (increaseDecreaseElementsScore * 100).toFixed(2) + '%' : increaseDecreaseElementsScore;

    const increaseDecreaseAccountsScore = filterBy === 'Accuracy' ? calculateAverage(equationScoresInsights, 'increase_decrease_accounts_accuracy') : calculateAverage(equationScoresInsights, 'increase_decrease_accounts_points');
    const increaseDecreaseAccountsFormattedScore = filterBy === 'Accuracy' ? (increaseDecreaseAccountsScore * 100).toFixed(2) + '%' : increaseDecreaseAccountsScore;

    const debitCreditsScore = filterBy === 'Accuracy' ? calculateAverage(equationScoresInsights, 'debits_credits_accuracy') : calculateAverage(equationScoresInsights, 'debits_credits_points');
    const debitCreditsFormattedScore = filterBy === 'Accuracy' ? (debitCreditsScore * 100).toFixed(2) + '%' : debitCreditsScore;

    wholeLeaderboardData = [
      {
        name: 'Increase or Decrease (Elements)',
        score: increaseDecreaseElementsScore,
        formattedScore: increaseDecreaseElementsFormattedScore,
        backgroundColor: '#A98DF6'
      },
      {
        name: 'Increase or Decrease (Accounts)',
        score: increaseDecreaseAccountsScore,
        formattedScore: increaseDecreaseAccountsFormattedScore,
        backgroundColor: 'rgba(169, 141, 246, 0.75)'
      },
      {
        name: 'Debits & Credits',
        score: debitCreditsScore,
        formattedScore: debitCreditsFormattedScore,
        backgroundColor: 'rgba(169, 141, 246, 0.5)'
      }
    ];

    setData(wholeLeaderboardData);
  }


  const handlePrevButtonClick = () => {
    navigate('/instructor/insights/decision-insights/classifying-normal/');
  }

  const handleNextButtonClick = () => {
    navigate('/instructor/insights/decision-insights/classification/1' );
  }

  const handleFilterClick = (selectedFilterBy) => {
    setFilterBy(selectedFilterBy);
  }

  const maxScore = data ? data.reduce((max, item) => (item.score > max ? item.score : max), -Infinity) : 0;

  return (
    <SubPagesLayout
      navigation={navigation}
      onFirstButtonClick={handlePrevButtonClick}
      onSecondButtonClick={handleNextButtonClick}
      currentPage={3}
      pagesQuantity={3}
      contentClassName="decision-insights-content-container"
    >
      <h1>Insights | Overall</h1>
      <h2>Double Entry</h2>
      <p>This chart, based on Balanced Equation exercise data, showcases your students' grasp of double-entry accounting. They analyzed transactions to determine their impact on Balance Sheet elements, identify specific accounts, and apply the correct debits and credits.</p>
      <LeaderboardOverall
        title="Performance"
        data={data}
        filterBy={filterBy}
        filterByOptions={filterByOptions}
        onFilterByClick={handleFilterClick}
        maxScore={maxScore}
        isBigScores={true}
      />
      <p><span className="red"><b>Note:</b></span> The chart will continuously update until the deadline has passed on all assignments.</p>
    </SubPagesLayout>
  );
}
