import axiosClient from '../../api/axiosClient.js';
import { fetchPageData } from '../../api/utils.js';

export async function getClassificationScores(gameId) {
  return fetchPageData(`/api/classification/scores/${gameId ? `?game=${gameId}` : ''}`);
}

export async function getNormalScores(gameId) {
  return fetchPageData(`/api/normal/scores/${gameId ? `?game=${gameId}` : ''}`);
}

export async function getProfitScores(gameId) {
  return fetchPageData(`/api/principles/scores/${gameId ? `?game=${gameId}` : ''}`);
}

export async function getBalancedEquationScores(gameId) {
  return fetchPageData(`/api/equation/scores/${gameId ? `?game=${gameId}` : ''}`);
}

export async function getClassificationScoresByQuestion(gameId) {
  return fetchPageData(`/api/classification/scores/by-question/?game=${gameId ? `?game=${gameId}` : ''}`);
}

export async function getNormalScoresByQuestion(gameId) {
  return fetchPageData(`/api/normal/scores/by-question/?game=${gameId ? `?game=${gameId}` : ''}`);
}

export async function getProfitScoresByQuestion(gameId) {
  const response = await axiosClient.get(`/api/principles/scores/principles/?game=${gameId ? `?game=${gameId}` : ''}`);
  return response.data;
}

export async function getBalancedEquationScoresByQuestion(gameId) {
  return fetchPageData(`/api/equation/scores/by-question/?game=${gameId ? `?game=${gameId}` : ''}`);
}

export async function getEquationScoresInsights(gameId) {
  return fetchPageData(`/api/equation/scores/insights/?game=${gameId ? `?game=${gameId}` : ''}`);
}